$(document).ready(function ($) {

	$('.eq').matchHeight();

	/**********************************************
	RESIZE END / SCROLL END
	**********************************************/

	var resizeEnd;
	$(window).on('resize', function () {
		clearTimeout(resizeEnd);
		resizeEnd = setTimeout(function () {
			$(window).trigger('resizeEnd');
		}, 100);
	});

	var scrollEnd;
	$(window).on('scroll', function () {
		clearTimeout(scrollEnd);
		scrollEnd = setTimeout(function () {
			$(window).trigger('scrollEnd');
		}, 100);
	});

	/**********************************************
	VIDEO JS
	**********************************************/

	var video = [];
	var player = [];
	var played = [];

	$('.video-js').each(function(i,el) {
		var id = '#video-js-' + i;
		video[i] = $(id);
		player[i] = new Plyr(id, {
			debug: true
		});
		played[i] = 0;

		$(window).on('load scroll', function () {

			if (inViewport(video[i]) && played[i] == 0) {

				setTimeout(function() {
					player[i].play();
					player[i].decreaseVolume(1000);
				},500);

				played[i] = 1;
			}
		});
	});

});

/**********************************************
VARIOUS FUNCTIONS
**********************************************/

function jQuerySelectorEscape(expression) {
	return expression.replace(/[!"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g, '\\$&');
}

// Zobrazení elementu ve viewportu
function inViewport(el) {
	var elH = $(el).height(),
			elTop = $(el).offset().top,
			scrollTop = $(window).scrollTop(),
			winH = $(window).height();
	fixedHeaderH = 0; // výška fixní hlavičky

	return ((elTop + elH) > (scrollTop + fixedHeaderH) && (scrollTop - elH) > (elTop - winH));
}
